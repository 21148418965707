<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>회원탈퇴</li>
						</ul>
					</div>
					<h2 class="page_title">회원탈퇴</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<h2 class="quit_title"><b>WAPU</b>를 이용해주셔서 감사합니다.</h2>
					<div class="quit_info">
						<strong>회원 탈퇴</strong>
						<ul class="info_list">
							<li>현재 사용중인 계정 정보는 회원 탈퇴 후 복구가 불가합니다.</li>
							<li>정기 결제가 남아있는 경우는 서비스 해지 후 탈퇴 신청이 가능합니다.</li>
							<li>탈퇴 후 회원님의 정보는 전자상거래 소비자보호법에 의거한 와퓨 개인정보처리방침에 따라 관리됩니다.</li>
							<li>현재 보유 중인 데이터 및 빅테이더 인프라는 모두 자동 삭제됩니다.</li>
							<li>탈퇴 후에는 계정 정보가 삭제되어 본인 확인이 불가하므로, 탈퇴 신청 전에 게시글 삭제를 요청해 주시기 바랍니다.</li>
						</ul>
					</div>
					<div class="quit_form">
						<strong>탈퇴 사유</strong>
						<ul class="form_list">
							<li>
								<div class="radio_box">
									<input type="radio" name="quit" id="select1" class="inp_radio" checked v-model="type" value="a">
									<label for="select1" class="lab_radio">서비스 이용하기가 불편해요.</label>
								</div>
							</li>
							<li>
								<div class="radio_box">
									<input type="radio" name="quit" id="select2" class="inp_radio" v-model="type" value="b">
									<label for="select2" class="lab_radio">정기결제 요금이 너무 비싸요.</label>
								</div>
							</li>
							<li>
								<div class="radio_box">
									<input type="radio" name="quit" id="select3" class="inp_radio" v-model="type" value="c">
									<label for="select3" class="lab_radio">오류가 많아요.</label>
								</div>
							</li>
							<li>
								<div class="radio_box">
									<input type="radio" name="quit" id="select4" class="inp_radio" v-model="type" value="d">
									<label for="select4" class="lab_radio">대체할 만한 서비스를 찾았어요.</label>
								</div>
							</li>
							<li>
								<div class="radio_box">
									<input type="radio" name="quit" id="select5" class="inp_radio" v-model="type" value="e">
									<label for="select5" class="lab_radio">기타</label>
								</div>
								<textarea id="etc-text" class="tta" cols="30" rows="5"  v-model="etcReason" maxlength="200" placeholder="기타 사유를 입력해주세요." disabled></textarea>
							</li>
						</ul>
					</div>

					<div class="quit_agree">
						<div class="check_box">
							<input type="checkbox" name="keep" id="agree2" class="inp_check" v-model="isAgree">
							<label for="agree2" class="lab_check">해당 내용을 모두 확인하였으며, 회원 탈퇴에 동의합니다.</label>
						</div>
					</div>
					<div class="button_area">
						<button type="submit" class="btn_red" @click="goQuit">회원 탈퇴</button>
					</div>

				</div>
			</div>

		</div>
	</main>
</template>

<script>

import $ from "jquery";

export default {
	name: "quit",
	data() {
		return {
			reason: '서비스 이용하기가 불편해요.',
			type: 'a',
			etcReason: '',
			isAgree: false
		}
	},
	mounted() {
	},
	methods: {

		goQuit() {
			const self = this;

			if (this.getSessionProc() == null || this.getSessionProc().emailId == null) {
				return this.swalUtils.gritter("로그인이 필요합니다.", "", "info", 3000)
			}

			if (!this.isAgree) {
				return this.swalUtils.gritter("회원 탈퇴에 동의해주세요", "", "info", 3000)
			} else {

				if(this.type == 'e' && this.etcReason == '') {
					return this.swalUtils.gritter("기타사유를 입력해주세요", "", "info", 3000)
				}

				let info = {}
				info.reason = this.reason;
				info.etc = this.etcReason;
				info.emailId = this.getSessionProc().emailId;


				this.$eventBus.$emit("startProgress")
				// 1. 회원 탈퇴 정보 저장
				self.axiosCaller.post(self, self.APIs.USER + "/saveQuitInfo", info, (res) => {
					let result = res.data;
					if (result.status === "ok") {

						// 2. 환불 및 탈퇴 진행
						self.axiosCaller.post(self, self.APIs.USER + "/deleteUser", { 'emailId' : self.getSessionProc().emailId }, (res) => {
							let result = res.data;
							if (result.status === "ok") {

								// 3. 완료처리
								self.swalUtils.gritter("탈퇴가 완료되었습니다.", "", "info", 3000)
										.then(() => {
											this.$session.destroy()
											location.href = "/member/successQuit"
										})

								self.$eventBus.$emit("doneProgress")

							} else {
								self.swalUtils.gritter("Error", result.msg, "error")
										.then(() => {
											location.href = document.referrer
										})
							}
						});

					} else {
						self.swalUtils.gritter("Error", result.msg, "error")
								.then(() => {
									location.href = document.referrer
								})
					}
				});
				// ajax 통신 끝

			}

		}

	},
	watch: {
		'type' : function (newVal) {
			switch (newVal) {
				case "a" :
					this.reason = '서비스 이용하기가 불편해요.';
					this.etcReason = '';
					$("#etc-text").attr("disabled", true);
					break;
				case "b" :
					this.reason = '정기결제 요금이 너무 비싸요.';
					this.etcReason = '';
					$("#etc-text").attr("disabled", true);
					break;
				case "c" :
					this.reason = '오류가 많아요.';
					this.etcReason = '';
					$("#etc-text").attr("disabled", true);
					break;
				case "d" :
					this.reason = '대체할 만한 서비스를 찾았어요.';
					this.etcReason = '';
					$("#etc-text").attr("disabled", true);
					break;
				case "e" :
					this.reason = '기타';
					$("#etc-text").attr("disabled", false);
					break;
			}
		}
	}
}
</script>

<style scoped>

</style>